<template lang="pug">
#mapContainer.w-full.h-full.rounded-md.border.border-secondary.z-10
  div(v-if="coords.length < 1") Pas d'offres disponible pour ces critères.
</template>

<script setup>
  import 'leaflet/dist/leaflet.css'
  import L from 'leaflet'
  import contractIcon from '~/assets/images/contrat.svg'
  import locationIcon from '~/assets/images/icon-location.svg'
  import timeIcon from '~/assets/images/icon-time.svg'

  const props = defineProps({
    locations: { type: Object, required: true },
    markerWithPopup: { type: Boolean, required: false },
    offres: { type: Object, required: false },
    zoomMap: { type: Number, required: false },
  })

  const dateDiff = (date1, date2) => {
    const diff = {}
    let tmp = date2 - date1

    tmp = Math.floor(tmp / 1000)
    diff.sec = tmp % 60

    tmp = Math.floor((tmp - diff.sec) / 60)
    diff.min = tmp % 60

    tmp = Math.floor((tmp - diff.min) / 60)
    diff.hour = tmp % 24

    tmp = Math.floor((tmp - diff.hour) / 24)
    diff.day = tmp

    return diff
  }

  const formatDateDay = (date) => {
    const options = {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
    }
    return new Date(date).toLocaleDateString('en', options)
  }

  const currentDate = new Date()

  const userCoord = [
    {
      coordX: 0.0833,
      coordY: 48.6667,
    },
  ]

  const coords = computed(() => {
    const positions = []
    if (props.locations.length) {
      for (let i = 0; i < props.locations.length; i++) {
        if (props.locations[i])
        positions.push({
            coordX: props.locations[i].lon,
            coordY: props.locations[i].lat,
          })
      }
    } else {
      if (props.locations?.lon && props.locations?.lat) {
        positions.push({
          coordX: props.locations.lon,
          coordY: props.locations.lat,
        })
      }
    }
    return positions
  })

  const zoomMap = props.zoomMap || 9

  let map = null

  const loadMap = () => {
    if (coords.value.length > 0) {
      map = L.map('mapContainer').setView(
        [coords.value[0].coordY, coords.value[0].coordX],
        zoomMap
      )
      L.tileLayer('http://{s}.tile.osm.org/{z}/{x}/{y}.png', {
        attribution:
          '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      }).addTo(map)
    } else {
      map = L.map('mapContainer').setView(
        [userCoord[0].coordY, userCoord[0].coordX],
        10
      )
      L.tileLayer('http://{s}.tile.osm.org/{z}/{x}/{y}.png', {
        attribution:
          '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      }).addTo(map)
    }

    const iconSettings = {
      mapIconUrl:
        '<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-10 h-10"><path stroke-linecap="round" stroke-linejoin="round" d="M15 10.5a3 3 0 11-6 0 3 3 0 016 0z" /><path stroke-linecap="round" stroke-linejoin="round" d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1115 0z" /></svg>',
    }
    const markerOrange = L.divIcon({
      className: 'marker-orange',
      html: L.Util.template(iconSettings.mapIconUrl, iconSettings),
      iconAnchor: [19, 10],
    })
    if (props.markerWithPopup) {
      if (props.offres.length < 1) return
      props.offres.map((offre) => {
        const datePublication = new Date(formatDateDay(offre.changed))
        const timer = dateDiff(datePublication, currentDate)

        if (offre.field_geofield) {
          const myIcon = L.divIcon({
            className: 'custom-marker',
            html: `<span>${offre.title}</span><div class="triangle"></div>`,
            iconAnchor: [75, 70],
            popupAnchor: [0, -85],
          })
          const typeContracts = ['Autres']
          if (!offre.field_type_de_contrat.data) {
            typeContracts.pop()
            for (let i = 0; i < offre.field_type_de_contrat.length; i++) {
              typeContracts.push(offre.field_type_de_contrat[i].name)
            }
          }
          if (offre.field_emploi_image_entreprise.image_style_uri) {
            return L.marker(
              [offre.field_geofield.lat, offre.field_geofield.lon],
              { icon: myIcon }
            )
              .addTo(map)
              .bindPopup(
                `<div class="popup-container"><a href="${
                  offre.path.alias
                }" class="w-1/3 mr-2.5 rounded-md hover:scale-105 transition-all transition-300 overflow-hidden cursor-pointer"><img src="${
                  offre.field_emploi_image_entreprise.image_style_uri.webp
                }" alt="${
                  offre.field_emploi_image_entreprise.meta.alt
                }" class="h-full w-full object-cover"></img></a><div class="popup-text"><a href="${
                  offre.path.alias
                }"><h2 class="hover:underline">${
                  offre.title
                }</h2></a><a href="${
                  offre.path.alias
                }"><h3 class="hover:underline">${
                  offre.field_entreprise.entreprise_profiles
                    ? offre.field_entreprise.entreprise_profiles
                        .field_raison_sociale
                    : 'Non renseigné'
                }</h3></a><div class="py-0.5 border-b border-secondary flex items-center space-x-1"><img src="${contractIcon}" alt="contract icon" class="h-4 w-4"></img><span class="text-primary popup-details">${
                  typeContracts.length > 1
                    ? typeContracts.join(', ')
                    : typeContracts[0]
                }</span></div><div class="py-0.5 border-b border-secondary flex items-center space-x-1"><img src="${locationIcon}" alt="location icon" class="h-4 w-4"></img><span class="text-secondary popup-details">${
                  offre.field_lieu_travail.locality
                    ? offre.field_lieu_travail.locality
                    : 'Non renseigné'
                }</span></div><div class="py-0.5 border-b border-secondary flex items-center space-x-1"><img src="${timeIcon}" alt="time icon" class="h-4 w-4"></img><span class="text-secondary popup-details">En ligne depuis ${
                  timer.day > 0
                    ? `${timer.day} jours`
                    : timer.hour > 0
                    ? `${timer.hour} heures`
                    : timer.min > 0
                    ? `${timer.min} minutes`
                    : `${timer.sec} secondes`
                }</span></div></div></div>`
              )
          } else {
            return L.marker(
              [offre.field_geofield.lat, offre.field_geofield.lon],
              { icon: myIcon }
            )
              .addTo(map)
              .bindPopup(
                `<div class="popup-container"><div class="popup-text no-img"><a href="${
                  offre.path.alias
                }"><h2 class="hover:underline">${
                  offre.title
                }</h2></a><a href="${
                  offre.path.alias
                }"><h3 class="hover:underline">${
                  offre.field_entreprise.entreprise_profiles
                    ? offre.field_entreprise.entreprise_profiles
                        .field_raison_sociale
                    : 'Non renseigné'
                }</h3></a><div class="py-0.5 border-b border-secondary flex items-center space-x-1"><img src="${contractIcon}" alt="contract icon" class="h-4 w-4"></img><span class="text-primary popup-details">${
                  typeContracts.length > 1
                    ? typeContracts.join(', ')
                    : typeContracts[0]
                }</span></div><div class="py-0.5 border-b border-secondary flex items-center space-x-1"><img src="${locationIcon}" alt="location icon" class="h-4 w-4"></img><span class="text-secondary popup-details">${
                  offre.field_lieu_travail.locality
                    ? offre.field_lieu_travail.locality
                    : 'Non renseigné'
                }</span></div><div class="py-0.5 border-b border-secondary flex items-center space-x-1"><img src="${timeIcon}" alt="time icon" class="h-4 w-4"></img><span class="text-secondary popup-details">En ligne depuis ${
                  timer.day > 0
                    ? `${timer.day} jours`
                    : timer.hour > 0
                    ? `${timer.hour} heures`
                    : timer.min > 0
                    ? `${timer.min} minutes`
                    : `${timer.sec} secondes`
                }</span></div></div></div>`
              )
          }
        }
      })
    } else {
      coords.value.map((c) =>
        L.marker([c.coordY, c.coordX], { icon: markerOrange }).addTo(map)
      )
    }
  }

  onUpdated(() => {
    if (map) {
      map.remove()
    }
    loadMap()
  })

  onBeforeUnmount(() => {
    if (map) {
      map.remove()
    }
  })
</script>

<style>
  .custom-marker {
    padding: 0.625rem 1.25rem !important;
    background-color: white;
    color: #46565e;
    font-weight: bold;
    width: auto !important;
    height: auto !important;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1rem;
    border-radius: 0.375rem;
    position: relative;
    transition: all 300ms;
  }

  .triangle {
    width: 0px;
    height: 0px;
    border-style: solid;
    border-width: 15px;
    border-color: white transparent transparent transparent;
    position: absolute;
    bottom: 0;
    top: 99%;
    left: 50%;
    transform: translateX(-50%);
    transition: all 300ms;
  }

  .custom-marker:hover,
  .custom-marker:focus {
    background-color: #e05012;
    color: white;
  }

  .custom-marker:hover .triangle,
  .custom-marker:focus .triangle {
    border-color: #e05012 transparent transparent transparent;
  }

  .popup-container {
    display: flex;
    width: 100%;
  }

  .leaflet-popup {
    bottom: 0;
    left: 0;
    margin-bottom: 0;
  }

  .leaflet-popup-content {
    width: 340px !important;
    margin: 0.625rem;
  }

  .leaflet-popup-tip-container {
    display: none;
  }

  .leaflet-popup-content-wrapper {
    box-shadow: none;
    border-radius: 0.375rem;
    width: auto;
  }

  .popup-text {
    width: 67%;
  }

  .no-img {
    width: 100%;
  }

  .popup-text h2 {
    letter-spacing: 0px;
    color: #46565e;
    margin-bottom: 0.25rem;
  }

  .popup-text h3 {
    color: #e05012;
    margin-bottom: 0.4rem;
    font-size: 9px;
  }

  .popup-details {
    font-size: 9px;
  }

  .marker-orange {
    color: #e05012;
  }
</style>
